import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic'
import { useDispatch } from 'react-redux';
import Book from '../../SVG/Book'
import Trash from '../../SVG/Trash'
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import RecommendationDetails from '../ApplicationReviewRecommendationDetails/RecommendationDetails';
import RecommenderModalAdd from '../../AddRecommender/RecommenderModal';
import Loader from '../../Loader/Loader';
import cookies from 'next-cookies';
import './application-review-attachment.scss'
import FlashMessage from '../../FlashMessage/Message';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
const ReactPlayer = dynamic(() => import('react-player'))
import { getUrlExtension } from '../../../Utility/ApplicationFormUtility'
import Paper from '../../SVG/Paper';
import { imageType, docType, videoType, audioType, MAIN_CONFIG, unsupportiveImageType } from '../../../../config/main';
import VideoPlayer from '../../VideoPlayer/VideoPlayer';
import ViewFile from '../../ViewFile/ViewFile';
import DocumentPreviewer from '../../DocumentPreviewer/DocumentPreviewer';
import { updateModalState } from '../../../hooks/currenttabIndexAction';
import { reduxPage } from '../../../Core/store';
import Preview from '../../SVG/Preview';
import { useRouter } from 'next/router';
/**
 * Returns Application Review Attachments component
 *
 * @param {object} props
 * @param {string} props.type
 * @param {string} props.name
 * @param {string} props.description
 *
 * @returns {ReactElement}
 */

function ApplicationReviewAttachments(props) {
  const {
    type,
    name,
    description,
    relationship='',
    applicant_recommendation_name,
    sfid,
    token,
    setModalState = () => { },
    applicationUpdateFunction = false,
    email,
    firstName,
    lastName,
    heroku_id = '',
    message,
    herokuRole,
    next_set_number,
    stepId,
    applicationId, 
    scholarshipId, 
    accountId, 
    items = [], 
    id, 
    urlObj,
    showDocuUploadButton,
    relationship_field_visible__c = false,
    type_of_relationship__c = 'Text',
    relationship_options__c = '',
    buttonStatus,
    hideRecommenderIdentity=false
  } = props
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const API = new KaleidoscopeAPI({});
  let url = urlObj?.doc_url__c
  const extension = getUrlExtension(url)?.toLowerCase();
  let userContext = cookies('context').context
  let adminContext = cookies('context').admincontext
  let hasClassName = (type === "recommendation" && userContext === "DONOR") || (userContext !== "APPLICANT" && type !== "file");
  const cdnBaseUrl = process.env.NEXT_PUBLIC_BLOB_CDN_URL;
  const blobBaseUrl = process.env.NEXT_PUBLIC_BLOB_BASE_URL;
  const regex = new RegExp(blobBaseUrl, 'gi');
  const newUrl = url?.replace(regex, cdnBaseUrl);
  const hideRecommenderIdentityToReviewer = userContext === MAIN_CONFIG.USER_CONTEXT.REVIEWER && hideRecommenderIdentity;

  const getCode =()=>{
    if (urlObj?.cloudflare_stream_url__c) {
      const startIndex = urlObj?.cloudflare_stream_url__c.indexOf("cloudflarestream.com/") + "cloudflarestream.com/".length;
      const endIndex = urlObj?.cloudflare_stream_url__c.indexOf("/watch", startIndex);
      // Extract the substring between startIndex and endIndex
      return urlObj?.cloudflare_stream_url__c.substring(startIndex, endIndex);
    }
  }
  const embedCode = getCode()
  /**State to manage the data */

  const [updateDetails, setUpdateDetails] = useState({
    name: name,
    email: email,
    firstName: firstName,
    lastName: lastName,
    message: message,
    description: description,
    relationship: relationship,
    applicant_recommendation_name: applicant_recommendation_name
  })


  useEffect(() => {
      setUpdateDetails({
        name: name,
        email: email,
        firstName: firstName,
        lastName: lastName,
        message: message,
        description: description,
        relationship: relationship,
        applicant_recommendation_name: applicant_recommendation_name
      })
  }, [name]);

  /**
   * remove the modal content and hide the modal.
   */
  const hideModal = (e) => {
    setModalState({
      visible: false,
      className: '',
      content: []
    });
  }
  const dispatch = useDispatch()
  const hideSecondaryModal =()=>{
    dispatch(updateModalState({
      visible: false,
      id: '',
      content:[]
    }))
  }
  const handleUrltype = (selectedUrl) => {
    let content;
    const extension = getUrlExtension(selectedUrl);
    if (audioType.includes(extension)) {
      const html = `<audio className='react-player ${sfid}' width="100%" controls={true}>
        <source src=${newUrl} type="audio/mp3" /> 
      </audio>`;
      content = <ViewFile handleClose={hideSecondaryModal} content={html} type='audio' />;
    } else if (videoType.includes(extension) || embedCode) {
      const html = !embedCode && `<video className='react-player ${sfid}' width="100%" height="100%" controls={true}>
      <source src=${newUrl} type="video/mp4" /> 
    </video>`
      content = <ViewFile handleClose={hideSecondaryModal} content={html} embedCode={embedCode} type='video' />;
    } else if (imageType.includes(extension)) {
      const html = `<img src=${selectedUrl} width='100%' height='100%' alt="Image" />`;
      content = <ViewFile handleClose={hideSecondaryModal} content={html} type='image' />;
    } else {
      content = <DocumentPreviewer scanUrl={selectedUrl} handleClose={hideSecondaryModal} />;
    }
    dispatch(updateModalState({
      visible: true,
      id: getContentId(extension),
      content
    }))
  }
  const getContentId = (extension) => {
    // Define a mapping of content types to their respective IDs
    const contentTypeMap = {
      doc: 'application-document-preview',
      audio: 'application-audio-file-preview',
      video: 'application-video-file-preview',
      image: 'application-image-file-preview',
    };
  
    // Determine the content type based on the file extension
    if (audioType.includes(extension)) {
      return contentTypeMap.audio;
    } else if (videoType.includes(extension) || embedCode) {
      return contentTypeMap.video;
    } else if (imageType.includes(extension)) {
      return contentTypeMap.image;
    } else {
      // Default to document previewer if none of the conditions match
      return contentTypeMap.doc;
    }
  };
  const handleView = async (e) => {
    e.preventDefault();
    if (type === 'recommendation') {
      setLoading(true);
      if (userContext === MAIN_CONFIG.USER_CONTEXT.DONOR) {
        router.push(`/client/scholarships/${scholarshipId}/applications/${applicationId}/recommendations/${sfid || heroku_id}`, undefined, { shallow: true })
      } else {    
        const responseData = await API.fetchApplicantRecommendationQuestions({ token: token, recommendationid: heroku_id ? heroku_id : sfid })
        if (responseData.applicant_recommendations) {
          setModalState({
            visible: true,
            className: 'reviewer-page__application-details',
            content: <RecommendationDetails details={responseData} handleClose={hideModal} name={updateDetails.name} email={updateDetails.description} setModalState={setModalState} userContext={userContext} />
          });
        }
        setLoading(false);
      }
    } else if (url) {
      handleUrltype(url)
    }
  }

  const handleOpenFile = () => window.open(url)

  const handleEdit = async (e) => {
    e.preventDefault();
    if (type === 'recommendation') {
      setLoading(true);
      router.push(`/client/scholarships/${scholarshipId}/applications/${applicationId}/recommendations/${sfid || heroku_id}`, undefined, { shallow: true })
    } else if (url) {
      window.open(url, '_blank')
    }
  }

  const closeRecommenderModal = () => {
    setModalState({ visible: false });
  }

  const handleRecommenderDetailsEdit = (e) => {
    e.preventDefault()

    let object = {
      first_name: updateDetails.firstName,
      last_name: updateDetails.lastName,
      email: updateDetails.email,
      message: updateDetails.message,
      relationship: (type_of_relationship__c === 'Picklist') ? { label: updateDetails.relationship, value: updateDetails.relationship } : updateDetails.relationship,
      id: id
    }

    setModalState({
      visible: true,
      id: 'recommender__create',
      content: <RecommenderModalAdd
        handleClose={closeRecommenderModal}
        handleSubmit={handleRecommenderSubmit}
        questions={[]}
        onChange={() => { }}  
        staticObj={object}
        applicationDependentConfigs={[]}
        herokuId={heroku_id}
        MsgText={''}
        type={'view rec'}
        recommenderList={items.filter(a => a.attachmentType === "recommendation")}
        dependentConfigsForVariousSteps={[]}
        herokuRole={herokuRole}
        editRecommenderDetails={true}
        relationshipFieldVisible={relationship_field_visible__c}
        relationshipType={type_of_relationship__c}
        relationshipOptions={relationship_options__c || ''}
      />
    });
  }

  const handleRecommenderSubmit = async (state, questionData, type) => {
    setLoading(true)
    let recommenderData = {
      first_name__c: state.first_name,
      last_name__c: state.last_name,
      email__c: state.email,
      reference_message__c: state.message,
      set_number: items.filter(a => a.attachmentType === "recommendation").length > 0 ? items.filter(a => a.attachmentType === "recommendation").map(a => a.question_set_number).lastItem : 1
    }

    let flag = true

    items.map((item) => {
      if (!state.id) {
        if (item.email === state.email) {
          flag = false
          toast.error(<FlashMessage type='error' message={`This email already exists.`} />)
        }
      } else if (item.id != state.id) {
        if (item.email === state.email) {
          flag = false
          toast.error(<FlashMessage type='error' message={`This email already exists.`} />)
        }
      }
    })

    if (flag) {
      const response = await API.recommenderUpdateForClient({ token: token, recommendationid: heroku_id, recommenderData, accountId, scholarshipId, applicationId });
      if (response.success) {
        closeRecommenderModal()
        toast.success(<FlashMessage message={response.message} />)
        setUpdateDetails({ firstName: state.first_name, lastName: state.last_name, email: state.email, message: state.message, name: state.first_name + "" + state.last_name, description: state.email })
      } else {
        toast.error(<FlashMessage type="error" message={response.error} />)
      }
    }
    setLoading(false)
  }

  const handleRecomDelete = async () => {
    const response = await API.recommenderDeleteForClient({ token: token, accountId, scholarshipId, applicationId, recommenderId: heroku_id, applicationStepId: stepId})
    if (response.success) {
      toast.success(<FlashMessage message={response.message} />)
      window.location.reload()
    } else {
      toast.error(<FlashMessage type="error" message={response.error} />)
    }    
  }

  const renderPreview=()=>{
    if (type === "recommendation") {
      return (
        <span className="H6DesktopGreen">
          {hideRecommenderIdentityToReviewer
            ? updateDetails.applicant_recommendation_name
            : updateDetails.name}
        </span>
      );
    }

    if (imageType.includes(getUrlExtension(url))) {
      return (
        <>
          <div className="application-review-attachment__preview-document">
            <button className="application-review-attachment__trigger" onClick={handleOpenFile}>
              {updateDetails.name}
            </button>
          </div>
          <div className="application-review-attachment__preview-image">
            <img src={url} width='100%' alt="Image" />
          </div>
        </>
      );
    }
  if(unsupportiveImageType.includes(getUrlExtension(url))){
    return (
      <div className="application-review-attachment__preview-document">
        <button className="application-review-attachment__trigger" onClick={handleView}>
          {updateDetails.name}
        </button>
      </div>
    );
  }
    if (docType.includes(getUrlExtension(url))) {
      return (
        <div className="application-review-attachment__preview-document">
          <button className="application-review-attachment__trigger" onClick={handleOpenFile}>
            {updateDetails.name}
          </button>
        </div>
      );
    }

    if (videoType.includes(getUrlExtension(url))) {
      return (
        <>
          {cookies('context').context !== 'APPLICANT' && (
            <div className='application-review-attachment-small-pdf-help-desc'>
              Note: If the video below does not appear, click the view button.
            </div>
          )}
          <div className="application-review-attachment__preview-document">
            <button className="application-review-attachment__trigger" onClick={handleOpenFile}>
              {updateDetails.name}
            </button>
          </div>
          {newUrl &&
         <>
              {embedCode ?
                <div className="application-review-attachment__preview-video">
                  <VideoPlayer embedCode={embedCode} />
                </div>
                :
                <div className="application-review-attachment__preview-video">
                  <ReactPlayer
                    className={"react-player " + sfid}
                    url={newUrl}
                    width='100%'
                    onError={(error) => console.log("error", error)}
                    controls={true}
                  />
                </div>
              }
            </>
          }
        </>
      );
    }

    if (audioType.includes(getUrlExtension(url))) {
      return (
        <>
          <div className="application-review-attachment__preview-document">
            <button className="application-review-attachment__trigger" onClick={handleOpenFile}>
              {updateDetails.name}
            </button>
          </div>
        <div className="application-review-attachment__preview-video audio-file-player">
          <ReactPlayer
            className={"react-player " + sfid}
            url={url}
            width="400px"
            height="50px"
            onError={(error) => console.log("error", error)}
            controls={true}
          />
        </div>
        </>
      );
    }

    // Default to an image preview if none of the above conditions match
    return (
      <div className="application-review-attachment__preview-image">
        <img src={url} width='100%' alt="Image" />
      </div>
    );

  }

  return (
    <>
    {type == "file" && <small className="application-review-attachment-small-pdf-help-desc">If you are having any issue in the viewer, please click on the file name to view the file in new tab.</small>}
    <div className="application-review-attachment" key={sfid}>
      <Loader
        loading={loading}
      />
      {(audioType.includes(getUrlExtension(url)) ||  videoType.includes(getUrlExtension(url)) || imageType.includes(getUrlExtension(url)))? "" : renderIcon(type)}
      <div className="application-review-attachment__main">
        <div>
          <h4 className="application-review-attachment__heading">
            <div className="application-review-attachment__preview">
              {renderPreview()}
            </div>
          </h4>
          <small className="application-review-attachment__small">
              {!(
                type == "recommendation" && hideRecommenderIdentityToReviewer
              ) && updateDetails.description}
            </small>
        </div>
        {userContext === "DONOR" && type !== "file" && !applicationUpdateFunction && <div className='view-recommender-record' onClick={(e) => handleRecommenderDetailsEdit(e)}>
        <Preview color={MAIN_CONFIG.COLORS.fillGreen} width={27} height={27} ariaLabel={"view"} ariaLabelDescription = "view record"/>
        </div>}
        {/* {userContext === "DONOR" && type !== "file" && !applicationUpdateFunction && <div>
          <button onClick={(e) => handleRecommenderDetailsEdit(e)}><PencilForDropDown className="edit-name-and-email-id" color={MAIN_CONFIG.COLORS.fillGreen} /></button>
        </div>} */}

        {userContext === "DONOR" && type !== "file" && !applicationUpdateFunction && <div>
          <button onClick={(e) => handleRecomDelete(e)}><Trash className="delete-recom" width={27} height={27} color={MAIN_CONFIG.COLORS.fillGreen} ariaLabel = {"Delete"} ariaLabelDescription = {"Delete Description"}/></button>
        </div>}


      </div>

      {
        !applicationUpdateFunction && (adminContext !== "ADMIN" || !isEmpty(userContext)) &&
        <div className={`application-review-attachment__trigger${hasClassName ? "-view-file" : ""}`}>
          {type == "file" &&
            <button className="application-review-attachment__trigger view-resource-file" onClick={(e) => handleView(e)} aria-label={`View button`} disabled = {buttonStatus}>
              {buttonStatus ? "Incomplete" : "View File"}
            </button>
          }
          {userContext !== "APPLICANT" && type !=="file" && userContext !== "DONOR" &&
            <button className="application-review-attachment__trigger view-resource" onClick={(e) => handleView(e)} aria-label={`View button`} disabled = {buttonStatus}>
              {buttonStatus ? "Incomplete" : "View"}
            </button>
          }
          {type === 'recommendation' && userContext === "DONOR" &&
            <button className="application-review-attachment__trigger edit-recommender" onClick={(e) => handleEdit(e)} aria-label={`Edit button`}>
              View/Edit
            </button>
          }
        </div>
      }

    </div>
    </>
  )
}

/**
 * Returns Attachment type icon
 *
 * @param {'recommendation' | 'file'} type
 *
 * @returns {ReactElement}
 */

function renderIcon(type) {
  let icon = null

  switch (type) {
    case 'recommendation':
      icon = <Book className="application-review-attachment__icon recommendation-file" />

      break
    case 'file':
      icon = <Paper height={20} width={20} color= {"blue"} className="application-review-attachment__icon paper-icon" />

      break
  }

  return icon
}
ApplicationReviewAttachments.propTypes = {
  applicant_recommendation_name: PropTypes.string,
  hideRecommenderIdentity: PropTypes.bool,

  urlObj: PropTypes.shape({
    doc_url__c: PropTypes.string,
    cloudflare_stream_url__c: PropTypes.string,
    // ... other expected properties
  }),
};
export default reduxPage(ApplicationReviewAttachments);