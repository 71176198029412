import React, { useEffect, useState } from 'react';
import WordMark from '../../Components/SVG/WordMark';
import BrokenPencil from '../../Components/SVG/BrokenPencil';
import ErrorForm from '../../Components/ErrorForm/Form';
import Loader from '../../Components/Loader/Loader';
import Modal from '../../Components/Modal/Modal';
import AppPage from '../../Core/AppPage';
import fetch from 'isomorphic-unfetch';
import { toast } from 'react-toastify';
import './Custom404.scss';

export default ({ pageData, setActiveHeaderNav, setModalState, accountData, message = "404 Page Not Found", hideHeader = false, BrokenPencilShow = true , showReportButton = true , id = ""}) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    sessionStorage.removeItem('previousPath')
  }, [])
  const handleClose = (e) => {
    e.preventDefault();
    setModalState({
      visible: false,
      className: '',
      content: ''
    })
  }

  const handleReportError = (e) => {
    e.preventDefault();
    setModalState({
      visible: true,
      className: 'report-error__page',
      content: <ErrorForm handleClose={handleClose} handleSave={handleError} />
    })
  }

  const handleError = async (e, data) => {
    // debugger
    e.preventDefault();
    setLoading(true);
    let requestData = {
      headers: {
        'Authorization': `Beacon Email=${data.email},DeviceId=67eec253-1641-420d-9216-29b2c8f8e266`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    }

    const dataObject = {
      "name": data.name,
      "email": data.email,
      "subject": `Error report from ${window.location.href}`,
      "text": data.comment,
      "comment": data.comment,
      "attachmentIds": [],
      "page":
      {
        "url": window.location.href,
        "title": "Home | Find Scholarship Programs | Kaleidoscope"
      },
      "activity": [
        {
          "type": "page-viewed",
          "timestamp": new Date().toISOString(),
          "url": window.location.origin,
          "title": "Home | Find Scholarship Programs | Kaleidoscope"
        },
        {
          "type": "page-viewed",
          "timestamp": new Date().toISOString(),
          "url": window.location.href,
          "title": "admin"
        },
        {
          "type": "page-viewed",
          "timestamp": new Date().toISOString(),
          "url": window.location.href,
          "title": "Home | Find Scholarship Programs | Kaleidoscope"
        }
      ]
    }

    requestData.body = JSON.stringify(dataObject);
    const response = await fetch(process.env.NEXT_PUBLIC_HELPSCOUT_URL, requestData);
    if ([200, 201].indexOf(response.status) > -1) {
      toast.success('Thanks for your patience, your problem has been reported');
      setModalState({
        visible: false,
        className: '',
        content: ''
      })
    }
    setLoading(false);
  }

  return (

    <div className="page-not-found">
      <Loader loading={loading} />
      <div className="page-not-found__center-box">
        <div className="page-not-found__center-box__top-section">
          <a href="/" onClick={() => setLoading(true)}><WordMark className="page-not-found__center-box__top-section__icons" /></a>
        </div>
        <div className="page-not-found__center-box__middle-section">
          {BrokenPencilShow && <BrokenPencil className="page-not-found__center-box__middle-section__icons" />}
         <h1 className="page-not-found__center-box__middle-section__message" id={id}>{message}</h1>
        </div>
        <div className="page-not-found__center-box__bottom-section">
          {!hideHeader &&
            <a href="/" onClick={() => setLoading(true)}>
              <button
                style={{
                  fontFamily: 'Inter-Medium',
                  fontSize: '16px',
                  fontWeight: '400',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: '1.5',
                  letterSpacing: 'normal',
                  color: '#fff',
                  backgroundColor: '#1C21DF',
                  border: 'none',
                  padding: '20px 24px',
                  borderRadius: '100px',
                  cursor: 'pointer',
                  boxSizing: 'border-box'
                }}
              >
                Go Back To Dashboard
              </button>
            </a>
          }

          {showReportButton && <a href="/" onClick={(e) => handleReportError(e)}>
            <button
              style={{
                fontFamily: 'Inter-Medium',
                fontSize: '16px',
                fontWeight: '400',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '1.5',
                letterSpacing: 'normal',
                color: '#000',
                backgroundColor: '#fff',
                border: 'none',
                padding: '20px 24px',
                borderRadius: '100px',
                cursor: 'pointer',
                boxSizing: 'border-box'
              }}
            >
              Report a Problem
            </button>
          </a>}
        </div>
      </div>
    </div >
  )

}
