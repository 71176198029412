import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import ApplicationReviewSections from '../../ApplicationReview/ApplicationReviewSections/ApplicationReviewSections'
// import './application.scss'
import FlashMessage from '../../FlashMessage/Message';
import { externalFinancialInformation, isUpload, recommenderSkip } from '../../../Utility/ApplicationFormUtility';


/**
 * Application form
 *
 * @returns {ReactElement}
 */

export default function ApplicationSteps({ accountData, application, scholarship = {}, account, setModalState, deleteApplicationDocument  = () => {}, addApplicationDocument  = () => {}, isActiveID }) {

  const [activeApplication, setActiveApplication] = useState(application);
  
  const renderIterativeReferenceContent = (step) => {
    let reference = []
    reference = !isEmpty(step.references) ? step.references.map((data) => {
      return {
        type: 'attachment',
        name: `${data.first_name} ${data.last_name}`,
        description: data.email,
        attachmentType: 'recommendation',
        sfid: data.sfid,
        deleteApplicationDocument: deleteApplicationDocument,
        addApplicationDocument: addApplicationDocument
      }
    }) : []

    let iterator = []
    iterator = step.iteration_data.added_questions.map((data, index) => {
      return {
        type: 'iteration',
        title: `Iteration Step - ${index + 1}`,
        setNumber: index + 1,
        items: data,
        dependentConfigsVariousSteps: step.dependent_configs_for_various_steps,
        deleteApplicationDocument: deleteApplicationDocument,
        addApplicationDocument: addApplicationDocument
      }
    })
    return reference.concat(iterator)
  }
  /**
   * Returns formatted data for iteration step with/without heroku role
   *
   * @returns {object[]}
   */
  const renderIterativeContent = (step) => {
    return step.heroku_role__c ? step.heroku_role__c === 'Financial Information' ? renderFinancialIterativeReferenceContent(step) : renderIterativeReferenceContent(step) : step.iteration_data.added_questions.map((data, index) => {
      return {
        type: 'iteration',
        title: `Iteration Step - ${index + 1}`,
        setNumber: index + 1,
        items: data,
        dependentConfigsVariousSteps: step.dependent_configs_for_various_steps,
        deleteApplicationDocument: deleteApplicationDocument,
        addApplicationDocument: addApplicationDocument
      }
    })
  }


  const renderFinancialIterativeReferenceContent = (step) => {

    return externalFinancialInformation(step.iteration_data.added_questions, step.references).map((data, index) => {
      return {
        type: 'FinancialInformation',
        title: `Iteration Step - ${index + 1}`,
        setNumber: index + 1,
        items: data,
        dependentConfigsVariousSteps: step.dependent_configs_for_various_steps,
        deleteApplicationDocument: deleteApplicationDocument,
        addApplicationDocument: addApplicationDocument
      }
    })
  }

  /**
   * Returns formatted step configs data for payment application step
   *
   * @returns {object[]}
   */
  const renderPayment = (step) => {
    return [{
      type: 'Payment',
      title: `Payment`,
      items: step
    }]
  }
  /**
   * Returns formatted step configs data for application step
   *
   * @returns {object[]}
   */
  const stepConfigsMarkup = (records = [], dependent_configs_for_various_steps = []) => {
    return records.map((field, index) => {
      return {
        type: 'text',
        ordinal: index + 1,
        heading: field.label__c,
        content: field,
        dataType: field.data_type__c,
        documents: field.document ? field.document : [],
        deleteApplicationDocument: deleteApplicationDocument,
        addApplicationDocument: addApplicationDocument
      }
    })
  }

  /**
   * Returns formatted data for application step
   *
   * @returns {object[]}
   */
  const renderStepData = (step) => {
    if (step.enable_iteration__c) {
      return stepConfigsMarkup(step.fields, step.dependent_configs_for_various_steps).concat(renderIterativeContent(step))
    } else if (step.payment_step__c) {
      return renderPayment(step)
    } else {
      return stepConfigsMarkup(step.fields, step.dependent_configs_for_various_steps)
    }
  }

  /**
   * Returns application step details
   *
   * @returns {[Object]}
   */
  const renderFormattedSections = (activeApplication) => {

    if (!isEmpty(activeApplication)) {
      if (!isEmpty(activeApplication.steps_data)) {
        const steps = Object.values(activeApplication.steps_data);
        return steps.map((step, index) => {
          let url = ''
          return {
            isActive: (isActiveID === step.sfid) ? true : false,
            heading: step.title__c,
            editURL: url,
            items: renderStepData(step),
            description: step.description__c
          }
        })
      }else{
        return []
      }
    }
  }

  /**
     * remove the modal content and hide the modal.
     */
  const hideModal = (e) => {
    setModalState({
      visible: false,
      className: '',
      content: []
    });
  }

  /**
   * Adds the modal content and shows the modal.
   */
  const showModal = async (e) => {
    e.preventDefault();

    const stepDataObj = Object.values(activeApplication.steps_data);
    setModalState({
      visible: true,
      className: 'submit-validation-modal-popup',
      content: <SubmitValidation handleClose={hideModal} stepDataObj={stepDataObj} scholarshipId={scholarshipId} applicationId={applicationId} isUpload={isUpload} recommenderSkip={recommenderSkip} />
    });

  }

  /**
     * submit the modal content and hide the modal.
     */
  const submitHideModal = async (e, ratingScore) => {
    e.preventDefault()
    setLoading(true)
    setModalState({
      visible: false,
      className: '',
      content: []
    });
    if (ratingScore) {
      let response = await API.ratingScoreSubmit({ 'token': accountData.token, scholarshipId: scholarshipId, applicationId: applicationId, ratingScore: ratingScore })
      if (response.success) {
        toast.success(<FlashMessage message={response.message} />);
        redirectToanotherScreen()
      } else {
        toast.error(<FlashMessage type='error' message={response.message} />);
        setLoading(false)
      }
    } else {
      redirectToanotherScreen()
    }
  }

  /**
   * Adds the modal content and shows the modal.
   */
  const submitModal = async (e) => {
    e.preventDefault();

    setModalState({
      visible: true,
      className: 'submit-thanky-you-modal-popup',
      content: <SubmitThankYou handleClose={submitHideModal} scholarship={scholarship} />
    });

  }

  return (
    <div className={` application `}>
      <div className="application__content">

          <ApplicationReviewSections
            setModalState={setModalState}
            sections={renderFormattedSections(activeApplication)}
          />

      </div>
    </div>
  )
}
