import React, { useState, useEffect } from 'react';
import SpaceInvader from '../../SVG/SpaceInvader'
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import Table from '../../Table/Table';
import CTAButton from '../../CTAButton/CTAButton';
// import './Donors.scss';
import Field from '../../Field/Field';
import Loader from '../../Loader/Loader';
import AirPlaneWithShadow from '../../SVG/AirPlaneWithShadow';
import { MAIN_CONFIG } from '../../../../config/main';
import LoginModal from '../../LoginModal/LoginModal';
import { checkMarketPlace } from '../../../Utility/HelperFunctions';
import SweetAlert from 'react-bootstrap-sweetalert';
import Globe from '../../SVG/Globe';
import History from '../../SVG/History';
import './AdminLogin.scss';
import Userinfo from '../../SVG/Userinfo';
import FlashMessage from '../../FlashMessage/Message';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { alretMsg } from '../../../Utility/ApplicationFormUtility';




export default ({ accountData, setModalState }) => {

    const router = useRouter();

    const [email, setEmail] = useState('');
    const [userDetail, setUserDetail] = useState('');
    const [loading, setLoading] = useState(false);
    const [updateAlert, setUpdateAlert] = useState(false);
    const { USER_ROLE_CONTEXT } = MAIN_CONFIG;
    const API = new KaleidoscopeAPI({});

    const searchUser = async (e) => {
        setLoading(true)
        e.preventDefault();
        const result = await API.userDetails({ token: accountData.token, data: { user_email: email } });
        if (result.user) {
            setUserDetail(result.user)
        } else {
            toast.error(<FlashMessage type="error" message={result.message||result.error||'Something went wrong'} />)
        }
        setLoading(false)
    }

    const {user} = accountData
    
    /**
     * remove the modal content and hide the modal.
     */
    const hideModal = (e) => {
        setModalState({
            visible: false,
            className: '',
            content: []
        });
    }

    /**
     * Adds the modal content and shows the modal.
     */
    const showModal = (e) => {
        e.preventDefault();
        let role = []
        role = userDetail.role.filter(r => r !== 'Admin')
        setModalState({
            visible: true,
            className: 'login-role-application__List',
            content: <LoginModal handleClose={hideModal} setLoading={setLoading} roles={role} handleLogin={selectRoleLogin} authDetail={userDetail} />
        });
    }

    const selectRoleLogin = (selectedRole, auth) => {
        setLoading(true)
        let userRoleArg = [];
        let roleContext = ''
        USER_ROLE_CONTEXT.map(role => {
            if (role.role === selectedRole) {
                userRoleArg.push(role);
            }
        })
        roleContext = userRoleArg.length > 0 ? userRoleArg[0] : ''
        if (roleContext) {

            if (roleContext.context === 'REVIEWER') {
                hideModal()
                document.cookie = `token=${auth.token};path=/`;
                document.cookie = `context=${roleContext ? roleContext.context : ''};path=/`;
                delete auth.avatar
                localStorage.setItem("elementsRBM", true)
                setTimeout(() => {
                    window.location.href = MAIN_CONFIG.ELEMENTS_URLS.REVIEWER.DASHBOARD;
                }, 5000);
            } else {
                hideModal()
                document.cookie = `token=${auth.token};path=/`;
                document.cookie = `context=${roleContext ? roleContext.context : ''};path=/`;
                delete auth.avatar
                //             document.cookie = `user=${JSON.stringify(auth)};path=/`;
                setTimeout(() => {
                    window.location.href = checkMarketPlace();
                }, 5000);
            }
        } else {
            const getAlert = () => (
                <SweetAlert
                    warning
                    confirmBtnCssClass="cta-button CTAWhite tertiary"
                    onConfirm={() => setUpdateAlert(false)}
                    id="copied-clipboard"
                >
                    <p className="CTAGrey">{selectedRole} role not supportted. </p>
                </SweetAlert>
            );
            setUpdateAlert(getAlert())
            hideModal()
        }
    }

    const resetDetail = (e) => {
        e.preventDefault();
        setEmail('')
        setUserDetail('')
    }

    const copyMagicLink = (e) => {
        e.preventDefault();
        window.navigator.clipboard.writeText(userDetail.reset_password_link)
        const getAlert = () => (
            <SweetAlert
                // success
                title="Success!"
                custom
                id="copied-clipboard"
                confirmBtnBsStyle="primary"
                customClass="user-confirmation-alert"
                openAnim="false"
                customIcon={MAIN_CONFIG.STATIC_IMAGE_URL.Success_Icon}
                customButtons={
                    <div className='action-footer'>
                      <button onClick={(e) => {
                     
                      setUpdateAlert(false)
                  }} className='cta-button CTAWhite tertiary cta-button__text'>Ok</button>
                    </div>
                  }
            >
                <p className="CTAGrey">Link is successfully copied to clipboard.</p>
            </SweetAlert>
        );
        setUpdateAlert(getAlert())
        document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        document.cookie = 'context=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        document.cookie = 'user=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        document.cookie = 'currentAccount=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    }

    const handleSuperAdminClick = async (e)=>{
        e.preventDefault()
        // setLoading(true)
        // const result = await API.userDetails({ token: accountData.token, data: { user_email: user.email } });
        // if (result.user) {
        //     document.cookie = `token=${result.user.token};path=/`;
        //     document.cookie = `context=DONOR;path=/`;
        //     router.push('/admin/dashboard', undefined, { shallow: true })
        // }
        window.location.href = MAIN_CONFIG.ELEMENTS_URLS.ADMIN.SUPER_ADMIN
    }
    const [isOpenSweetAlert, setIsOpenSweetAlert] = React.useState(false)
    const showUnsubscribeConfirmationPopup = localStorage.getItem('show_unsubscribe_confirmation') || false

    useEffect(() => {
        if (showUnsubscribeConfirmationPopup === 'true') {
            alretMsg("", setIsOpenSweetAlert, () => { }, false, '', () => {
                localStorage.setItem('show_unsubscribe_confirmation', false);
            },
                'Email Unsubscribe Confirmation',
                true,
                false
            );
            const timeoutId = setTimeout(() => {
                setIsOpenSweetAlert(false);
                localStorage.setItem('show_unsubscribe_confirmation', false);
            }, 30000);
            return () => clearTimeout(timeoutId);
        }
    }, []);
    return (
       <>
        <div className="admin-main">
            {updateAlert}
            <Loader loading={loading} />
            <div className="admin-login-menu">
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <h1 className="H1DesktopGreen admin-welcome-message"><SpaceInvader id="user-account-page-user-icon" /> {`Welcome, ${user?.first_name + " " + user?.last_name}`}</h1>
            <div className="admin-header__search-wrapper login-btn-admin-dashboard">
                <CTAButton id='reset' type='primary' onClick={(e) => handleSuperAdminClick(e)}>
                    Elements Super Admin
                </CTAButton>
            </div>    
    </div>  
                <div className="logged-in-As">
                    <div className="section group">
                        <div className="col span_12_of_12">
                            <div className="H5DesktopGreen">Get User Information</div>
                            <hr className="H5HeadingDivider" />
                            <form onSubmit={(e) => searchUser(e)}>
                                <Field
                                    id='admin-login-email-id'
                                    label='Email ID'
                                    placeholder='Enter Email ID'
                                    type='email'
                                    toolTipMessage='Get the user information like - Heroku ID and Password Reset Link'
                                    handleChange={value => setEmail(value)}
                                    value={email}
                                    required
                                />
                                <div className="footer-btn">
                                    <CTAButton id='search-user' type='primary'>
                                        {/* <CTAButton id='search-user' type='primary' IconComponent={Globe} iconProps={{ color: MAIN_CONFIG.COLORS.fillGreen }}> */}
                                        Search
                                    </CTAButton>
                                    <CTAButton id='reset' type='primary' onClick={(e) => resetDetail(e)}>
                                        Reset
                                    </CTAButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/*<div className="user-password-reset-link">
                    <div className="section group">
                        <div className="col span_12_of_12">
                            <div className="H5DesktopGreen">User Password Reset Link</div>
                            <hr className="H5HeadingDivider" />
                            <Field
                                id='admin-login-email-id'
                                label='Email id'
                                placeholder='Email id'
                                type='email'
                                toolTipMessage='User Password Reset Link'
                            />
                            <div className="footer-btn">
                                <CTAButton id='get-link' type='primary'>
                                    Get Link
                                </CTAButton>
                                <CTAButton id='reset'>
                                    Reset
                                </CTAButton>
                            </div>
                        </div>
                    </div>
                </div>*/}

            </div>
            {/*  */}


            {/*  */}
            {userDetail &&
                <div className="logged-in-As-output">
                    <div className="section group">
                        <div className="col span_12_of_12">

                            <table>
                                <thead>
                                    <tr>
                                        <th className="EyebrowGrey">
                                            Name
                                        </th>
                                        <th className="EyebrowGrey">
                                            Email ID
                                        </th>
                                        <th className="EyebrowGrey">
                                            Heroku ID
                                        </th>
                                        <th className="EyebrowGrey">
                                            Login
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="BodyDefaultRegularBlack">
                                            {`${userDetail.first_name} ${userDetail.last_name}`}
                                        </td>
                                        <td className="BodyDefaultRegularBlack">
                                            {userDetail.email}
                                        </td>
                                        <td className="BodyDefaultRegularBlack">
                                            <span className='heroku-user-id-highlight'>{userDetail.id}</span>
                                        </td>
                                        <td>
                                            <CTAButton id='legacy-login-as' type='primary' onClick={(e) => showModal(e)}>
                                                Click Here
                                            </CTAButton>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            {/*  */}

            {/*  */}
            {userDetail &&
                <div className="user-password-reset-link-output">
                    <div className="section group">
                        <div className="col span_12_of_12">
                            <Field
                                id='user-password-reset-link-field'
                                label='User Password Reset Link'
                                placeholder='https://app-dev.mykaleidoscope.com/password-recovery/create?token=Bearer%20eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo5MDI0LCJlbWFpbCI6InBhbmthanRlc3Rpbmd5MUBtYWlsaW5hdG9yLmNvbSIsInJvbGUiOlsiQXBwbGljYW50Il0sImV4cCI6MTYyODE2OTY0Mn0.m5p-xznSgfRv05FfqXOWU1iLYAhC2eG9K7mTob7K-aU'
                                type='textarea'
                                toolTipMessage='User Password Reset Link'
                                value={userDetail.reset_password_link}
                                disabled
                            />
                            <div className="footer-btn">
                                <CTAButton id='get-link' type='primary' onClick={(e) => copyMagicLink(e)}>
                                    Copy Link
                                </CTAButton>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {/*  */}
        </div >
        {isOpenSweetAlert}
       </>
    )
}
