import React, { useState, useEffect } from 'react';
import { isArray } from 'lodash';
import SpaceInvader from '../../SVG/SpaceInvader'
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import CTAButton from '../../CTAButton/CTAButton';
// import './Donors.scss';
import Field from '../../Field/Field';
import { TableListingLoader } from '../../Loader/Loader';
import AirPlaneWithShadow from '../../SVG/AirPlaneWithShadow';
import SweetAlert from 'react-bootstrap-sweetalert';
import Paginator from '../../Paginator/Paginator';
import Globe from '../../SVG/Globe';
import History from '../../SVG/History';
import './UserEmailHistory.scss';
import Userinfo from '../../SVG/Userinfo';
import Calendar from '../../SVG/Calendar';
import Eyes from '../../SVG/Eyes';
import Emailhistory from '../../SVG/Emailhistory';
import FormatDate from '../../../Utility/FormatDate';
import FlashMessage from '../../FlashMessage/Message';
import { toast } from 'react-toastify';
import { parseHtml } from '../../../Utility/HelperFunctions';

export default ({ accountData, setModalState }) => {

    const API = new KaleidoscopeAPI({});

    const [email, setEmail] = useState('');
    const [userActivitys, setUserActivity] = useState([]);
    const [loading, setLoading] = useState(false);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTotalRecords, setCurrentTotalRecords] = useState(0);

    /**
     * Fetches the data for the specified page number.
     * @param {number} page
     */
    const handlePagination = async (page) => {
        setCurrentPage(page)
        setLoading(true)
        await apiCall(page)
    }

    const resetDetail = (e) => {
        e.preventDefault();
        setUserActivity([])
        setEmail('')
        setCurrentPage(0)
        setCurrentTotalRecords(0)
    }

    const searchUser = async (e) => {
        e.preventDefault();
        setLoading(true)
        await apiCall(1)
    }

    const apiCall = async (page) => {
        const result = await API.userEmailHistory({ token: accountData.token, userEmail: email, page: page });
        if (result?.emails?.length > 0) {
            setUserActivity(result.emails)
            setCurrentTotalRecords(result.pagination.totalRecords)
        } else {
            toast.error(<FlashMessage type="error" message={"Nothing to find"} />)
        }
        setLoading(false)
    }

    const getRecord = (data) => {
        const createMarkup = (string) => {
            return { __html: string };
        }

        if (isArray(data)) {
            return data.map((record, index) => {
                return (
                    <tr>
                        <td dangerouslySetInnerHTML={createMarkup(record.label__c || record.question)}></td>
                        <td>{record.answer}</td>
                    </tr>
                )
            })
        }
    }

    const activityData = (activity) => {
        if (activity.params) {
            let data = JSON.parse(activity.params)
            const keys = Object.keys(data)
            const values = Object.values(data)
            return keys.map((key, index) => {
                if (typeof (data[key]) === 'object') {
                    return getRecord(data[key])
                } else {
                    return (
                        <tr>
                            <td>{key}</td>
                            <td>{values[index]}</td>
                        </tr>
                    )
                }
            })
        }

    }

    // 11-Aug-2021 | 10:30 PM (IST)
    return (
        <div className="admin-main">
            {/*  */}
            <div className="user-activities-menu">
                <div className="user-activities">
                    <div className="section group">
                        <div className="col span_12_of_12">
                            <form onSubmit={(e) => searchUser(e)}>
                                <div className="H5DesktopGreen">Email History</div>
                                <hr className="H5HeadingDivider" />
                                <Field
                                    id='user-activity'
                                    label='Email ID'
                                    placeholder='Enter Email ID'
                                    type='text'
                                    toolTipMessage='Get the user email history by Email ID'
                                    handleChange={value => setEmail(value)}
                                    value={email}
                                    required
                                />
                                <div className="footer-btn">
                                    <CTAButton id='get-activities' type='primary'>
                                        Get Activities
                                    </CTAButton>
                                    <CTAButton id='reset' onClick={(e) => resetDetail(e)}>
                                        Reset
                                    </CTAButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {(loading) ? <div className="skeleton-loader__container"><TableListingLoader /></div> : userActivitys.length > 0 &&
                    <div className="email-history-menu">
                        <div className="email-history">
                            <div className="section group">
                                <div className="col span_12_of_12">
                                    <div className="H5DesktopGreen">Email History</div>
                                    <hr className="H5HeadingDivider" />

                                    <div className="timeline">
                                        {userActivitys.map(email => {
                                            const omitString = [
                                                'text-align: center;',
                                                'width:100%;',
                                                'height:50px;'
                                            ];
                                            let omitedBody = email.body;
                                            omitString.forEach((str) => {
                                                if(omitedBody.includes(str)){
                                                    const omitStartIndex = omitedBody.indexOf(str);
                                                    const omitStringLength = str.length;
                                                    omitedBody = omitedBody.slice(0, omitStartIndex) + omitedBody.slice(omitStartIndex + omitStringLength);
                                                }
                                            })

                                            return (
                                                <>
                                                    <div className="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                                                        <div className="timeline__event__icon ">
                                                            <div className="timeline__event__date">
                                                                <div className="date">Delivered</div>
                                                            </div>
                                                        </div>
                                                        <div className="timeline__event__content ">
                                                            <div>
                                                                <div className="timeline__event__title">
                                                                    <Emailhistory width={24} height={24} /> Subject: <span className="email-subject">{email.subject}</span>
                                                                </div>
                                                                <div className="timeline__event__description">
                                                                    <Calendar width={30} height={30} /> Date : {email.created_at ? FormatDate(email.created_at, 'MMM d yyyy t') : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="timeline__event__expand">
                                                            <Eyes />
                                                        </div>
                                                    </div>
                                                    <div className="timeline__event view-email-details">
                                                        <div className="view-email-details__output">
                                                            {parseHtml(omitedBody)}
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Paginator
                            currentPage={currentPage}
                            totalRecords={currentTotalRecords}
                            perPage={perPage}
                            handlePageClick={handlePagination}
                        />
                    </div>
                }
            </div>
            {/*  */}
        </div>
    )
}