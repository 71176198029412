import React, { useState } from "react";
import CTAButton from "../CTAButton/CTAButton";
import Field from "../Field/Field";
import "./AdminSSoDetailsModal.scss";
import { uuid4 } from "@sentry/utils";
import Trash from "../SVG/Trash";
import { toast } from "react-toastify";
import { getDynamicClassName } from "../../Utility/ApplicationFormUtility";

export default ({
  handleSubmit = () => {},
  handleChange,
  accountId,
  newFeildMappings = {},
  setNewFeildMappings = () => {},
}) => {
  const { sso_detail } = newFeildMappings;

  const {
    idp_entity_id,
    idp_sso_target_url,
    idp_slo_target_url,
    is_active,
    idp_certificate,
    sp_entity_id,
    sp_init_url,
    sp_consume_url,
    primary_identifier,
    secondary_identifier,
    field_mappings,
    idp_client_id,
    idp_client_secret,
    sso_type,
    redirect_url,
    idp_api_scopes,
  } = sso_detail;

  const handleAddNewField = () => {
    setNewFeildMappings({
      sso_detail: {
        ...sso_detail,
        field_mappings: [
          ...field_mappings,
          { key: "", value: "", id: uuid4() },
        ],
      },
    });
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    toast.error("Deleted");
    const newData = field_mappings.filter((data) => {
      return data.id !== id;
    });

    

    setNewFeildMappings({
      sso_detail: {
        ...sso_detail,
        field_mappings: [...newData],
      },
    });
  };

  const handleTabClick = (tabIndex) => {
    setNewFeildMappings({
      sso_detail: {
        ...sso_detail,
        sso_type: tabIndex,
      },
    });
  };

  return (
    <>
      <div className="tabs BodyDefaultRegularBlack">
        <button className={getDynamicClassName(sso_type === "SAML 2.0", "tab active", "tab")} onClick={() => handleTabClick("SAML 2.0")}> SAML 2.0 </button>
        <button className={getDynamicClassName(sso_type === "OpenID Connect", "tab active", "tab")} onClick={() => handleTabClick("OpenID Connect")}> OpenID Connect </button>
      </div>


      {(sso_type === "SAML 2.0" ) && (
        <div className="update-recommender">
          <div className="section group">
            <div className="sso-details-main-div">
              <div id="task-modal-content" className="iteration-modal-box">
                <div className="section group">
                  <div className="col span_12_of_12">
                    <div className="H5DesktopGreen">SAML SSO Details</div>
                    {/* <hr className="H5HeadingDivider" /> */}
                  </div>
                </div>

                <div className="section group">
                  <div className="col span_12_of_12 sp-details">
                    <div className="sp-details-div">
                      <div className="left-div">
                        <div className="PDesktopGreen">SP Entity ID </div>
                        <div className="PDesktopGreen">SP Init URL </div>
                        <div className="PDesktopGreen">SP Consume URL</div>
                      </div>

                      <div className="mid-div">
                        <div className="PDesktopGreen">:</div>
                        <div className="PDesktopGreen">:</div>
                        <div className="PDesktopGreen">:</div>
                      </div>

                      <div className="right-div">
                        <div className="PDesktopGreen"> {sp_entity_id}</div>
                        <div className="PDesktopGreen"> {sp_init_url}</div>
                        <div className="PDesktopGreen"> {sp_consume_url}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="H5HeadingDivider" />

                <div className="user-task-add__form">
                  <form
                    className="applicant-profile__form"
                    onSubmit={(e) => handleSubmit(e, accountId)}
                  >
                    <div className="sso-details">
                      <div className="section group">
                        <div className="profile-field-column">
                          <Field
                            id="IDP__entity__id"
                            label="IDP Entity Id"
                            placeholder="IDP Entity Id"
                            type="text"
                            value={idp_entity_id}
                            handleChange={(val) => handleChange("idp_entity_id", val, "sso_detail")}

                            required={true}
                          />
                        </div>
                        <div className="profile-field-column">
                          <Field
                            id="idp__sso__target_url"
                            label="IDP SSO Target Url"
                            placeholder="IDP SSO Target Url"
                            type="text"
                            value={idp_sso_target_url}
                            handleChange={(val) =>
                              handleChange(
                                "idp_sso_target_url",
                                val,
                                "sso_detail"
                              )
                            }
                            required={true}
                          />
                        </div>
                        <div className="profile-field-column">
                          <Field
                            id="idp__slo_target_url"
                            label="IDP SLO Target Url"
                            placeholder="IDP SLO Target Url"
                            type="text"
                            value={idp_slo_target_url}
                            handleChange={(val) =>
                              handleChange(
                                "idp_slo_target_url",
                                val,
                                "sso_detail"
                              )
                            }
                          />
                        </div>
                        <div className="profile-field-column sso_checkbox">
                          <input
                            className=""
                            type="checkbox"
                            onChange={(e) =>
                              handleChange(
                                "is_active",
                                e.target.checked,
                                "sso_detail"
                              )
                            }
                            checked={is_active}
                          />
                          <span className="CTAGrey">Is Active</span>
                        </div>
                      </div>
                      <div className="profile-field-column">
                        <Field
                          id="idp__cert"
                          label="IDP Cert"
                          placeholder="IDP Cert"
                          type="textarea"
                          value={idp_certificate}
                          handleChange={(val) =>
                            handleChange("idp_certificate", val, "sso_detail")
                          }
                          required={true}
                        />
                      </div>
                    </div>

                    <div className="sso-primary">
                      <div className="task-head_container">
                        <h1 className="H2DesktopGreen event-head">
                          Primary Identifier
                        </h1>
                      </div>

                      <div className="sso-primary-content section group">
                        <Field
                          id="idp__slo_target_url"
                          placeholder="Primary Identifier Key"
                          type="text"
                          value={primary_identifier[0].key}
                          handleChange={(val) =>
                            handleChange("", val, "primary_identifier", "key")
                          }
                          required={true}
                        />
                        <Field
                          id="idp__slo_target_url"
                          placeholder="Primary Identifier Value"
                          type="text"
                          value={primary_identifier[0].value}
                          handleChange={(val) =>
                            handleChange("", val, "primary_identifier", "value")
                          }
                          required={true}
                        />
                      </div>
                    </div>

                    <div className="sso-secondary">
                      <div className="task-head_container">
                        <h1 className="H2DesktopGreen event-head">
                          Secondary Identifier
                        </h1>
                      </div>

                      <div className="sso-primary-content section group">
                        <Field
                          id="idp__slo_target_url"
                          // label='IDP SLO Target Url'
                          placeholder="Secondary Identifier Key"
                          type="text"
                          value={secondary_identifier[0].key}
                          handleChange={(val) =>
                            handleChange("", val, "secondary_identifier", "key")
                          }
                        />
                        <Field
                          id="idp__slo_target_url"
                          // label='IDP SLO Target Url'
                          placeholder="Secondary Identifier Value"
                          type="text"
                          value={secondary_identifier[0].value}
                          handleChange={(val) =>
                            handleChange(
                              "",
                              val,
                              "secondary_identifier",
                              "value"
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="sso-feild-mappings">
                      <div className="task-head_container">
                        <h1 className="H2DesktopGreen event-head">
                          Field Mappings
                        </h1>
                      </div>

                      {field_mappings.map((entry) => {
                        return (
                          <>
                            <div className="sso-primary-content">
                              <div className="profile-field-column">
                                <Field
                                  id="idp__slo_target_url"
                                  placeholder="Field Mapping Key"
                                  type="text"
                                  value={entry.key}
                                  handleChange={(val) =>
                                    handleChange(
                                      "",
                                      val,
                                      "field_mappings",
                                      "key",
                                      entry.id
                                    )
                                  }
                                  required={true}
                                />
                              </div>
                              <div className="profile-field-column">
                                <Field
                                  id="idp__slo_target_url"
                                  placeholder="Field Mapping Value"
                                  type="text"
                                  value={entry.value}
                                  handleChange={(val) =>
                                    handleChange(
                                      "",
                                      val,
                                      "field_mappings",
                                      "value",
                                      entry.id
                                    )
                                  }
                                  required={true}
                                />
                              </div>
                              <CTAButton
                                type="primary"
                                className="field"
                                small
                                onClick={(e) => handleDelete(e, entry.id)}
                                id="delete-user"
                              >
                                <Trash width={24} height={24} />
                              </CTAButton>
                              {/* <div className="element-round-form-btn">
                        <CTAButton type='primary' small onClick={(e) => handleDelete(e, entry.id)} id="delete-user">
                          <Trash width={24} height={24} /> 
                        </CTAButton>
                      </div> */}
                            </div>
                          </>
                        );
                      })}

                      {/* <div
                        className="sso-primary-content field-mapping-button"
                        onClick={handleAddNewField}
                      >
                        <div className="profile-field-column">
                          <Field
                            placeholder="Add New Field Mapping..."
                            noMargin
                            disabled={true}
                          />
                        </div>
                        <div className="profile-field-column">
                          <Field
                            placeholder="Add New Field Mapping..."
                            noMargin
                            disabled={true}
                          />
                        </div>
                      </div> */}
                      <div className="sso-new-field-content field-mapping-button" onClick={handleAddNewField}>
                        <div className="add-new-field-column">
                          <span className="custom-field-sso CTAGrey" onClick={handleAddNewField}>
                            Add New Field Mapping
                          </span>
                        </div>
                        <div className="add-new-field-column">
                          <span className="custom-field-sso CTAGrey" onClick={handleAddNewField}>
                            Add New Field Mapping
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="element-round-form-btn-submit">
                      <CTAButton id="task-page__cta" type="tertiary">
                        Submit
                      </CTAButton>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}


      {(sso_type === "OpenID Connect") && (
        <div className="update-recommender">
          <div className="section group">
            <div className="sso-details-main-div">
              <div id="task-modal-content" className="iteration-modal-box">
                <div className="section group">
                  <div className="col span_12_of_12">
                    <div className="H5DesktopGreen">OpenID SSO Details</div>
                    {/* <hr className="H5HeadingDivider" /> */}
                  </div>
                </div>

                <div className="section group">
                  <div className="col span_12_of_12 sp-details">
                    <div className="sp-details-div">
                      <div className="left-div">
                        <div className="PDesktopGreen">SP Init URL </div>
                        <div className="PDesktopGreen">Redirect URL </div>
                      </div>

                      <div className="mid-div">
                        <div className="PDesktopGreen">:</div>
                      </div>

                      <div className="right-div">
                        <div className="PDesktopGreen"> {sp_init_url}</div>
                        <div className="PDesktopGreen"> {redirect_url}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="H5HeadingDivider" />

                <div className="user-task-add__form">
                  <form
                    className="applicant-profile__form"
                    onSubmit={(e) => handleSubmit(e, accountId)}
                  >
                    <div className="sso-details">
                      <div className="section group">
                        <div className="profile-field-column">
                          <Field
                            id="idp__client__secret"
                            label="IDP Client Secret"
                            placeholder="IDP Client Secret "
                            type="text"
                            value={idp_client_secret}
                            handleChange={(val) =>
                              handleChange("idp_client_secret", val, "sso_detail")
                            }
                          />
                        </div>

                        <div className="profile-field-column">
                          <Field
                            id="idp__client__id"
                            label="IDP Client ID"
                            placeholder="IDP Client ID"
                            type="text"
                            value={idp_client_id}
                            handleChange={(val) =>
                              handleChange("idp_client_id", val, "sso_detail")
                            }
                            required={true}
                          />
                        </div>

                        <div className="profile-field-column">
                          <Field
                            id="idp__sso__target_url"
                            label="IDP SSO Target Url"
                            placeholder="IDP SSO Target Url"
                            type="text"
                            value={idp_sso_target_url}
                            handleChange={(val) =>
                              handleChange(
                                "idp_sso_target_url",
                                val,
                                "sso_detail"
                              )
                            }
                            required={true}
                          />
                        </div>

                        <div className="profile-field-column">
                          <Field
                            id="idp__api__scopes"
                            label="IDP API Scopes"
                            placeholder="IDP API Scopes"
                            type="text"
                            value={idp_api_scopes}
                            handleChange={(val) =>
                              handleChange("idp_api_scopes", val, "sso_detail")
                            }
                            required={true}
                          />
                        </div>

                        <div className="profile-field-column sso_checkbox sso__checkbox">
                          <input
                            className=""
                            type="checkbox"
                            onChange={(e) =>
                              handleChange(
                                "is_active",
                                e.target.checked,
                                "sso_detail"
                              )
                            }
                            checked={is_active}
                          />
                          <span className="CTAGrey">Is Active</span>
                        </div>
                      </div>
                    </div>

                    
                    <div className="sso-primary">
                      <div className="task-head_container">
                        <h1 className="H2DesktopGreen event-head">
                          Primary Identifier
                        </h1>
                      </div>

                      <div className="sso-primary-content section group">
                        <Field
                          id="idp__slo_target_url"
                          placeholder="Primary Identifier Key"
                          type="text"
                          value={primary_identifier[0].key}
                          handleChange={(val) =>
                            handleChange("", val, "primary_identifier", "key")
                          }
                          required={true}
                        />
                        <Field
                          id="idp__slo_target_url"
                          placeholder="Primary Identifier Value"
                          type="text"
                          value={primary_identifier[0].value}
                          handleChange={(val) =>
                            handleChange("", val, "primary_identifier", "value")
                          }
                          required={true}
                        />
                      </div>
                    </div>

                    <div className="sso-secondary">
                      <div className="task-head_container">
                        <h1 className="H2DesktopGreen event-head">
                          Secondary Identifier
                        </h1>
                      </div>

                      <div className="sso-primary-content section group">
                        <Field
                          id="idp__slo_target_url"
                          // label='IDP SLO Target Url'
                          placeholder="Secondary Identifier Key"
                          type="text"
                          value={secondary_identifier[0].key}
                          handleChange={(val) =>
                            handleChange("", val, "secondary_identifier", "key")
                          }
                        />
                        <Field
                          id="idp__slo_target_url"
                          // label='IDP SLO Target Url'
                          placeholder="Secondary Identifier Value"
                          type="text"
                          value={secondary_identifier[0].value}
                          handleChange={(val) =>
                            handleChange(
                              "",
                              val,
                              "secondary_identifier",
                              "value"
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="sso-feild-mappings">
                      <div className="task-head_container">
                        <h1 className="H2DesktopGreen event-head">
                          Field Mappings
                        </h1>
                      </div>

                      {field_mappings.map((entry) => {
                        return (
                          <>
                            <div className="sso-primary-content">
                              <div className="profile-field-column">
                                <Field
                                  id="idp__slo_target_url"
                                  placeholder="Field Mapping Key"
                                  type="text"
                                  value={entry.key}
                                  handleChange={(val) =>
                                    handleChange(
                                      "",
                                      val,
                                      "field_mappings",
                                      "key",
                                      entry.id
                                    )
                                  }
                                  required={true}
                                />
                              </div>
                              <div className="profile-field-column">
                                <Field
                                  id="idp__slo_target_url"
                                  placeholder="Field Mapping Value"
                                  type="text"
                                  value={entry.value}
                                  handleChange={(val) =>
                                    handleChange(
                                      "",
                                      val,
                                      "field_mappings",
                                      "value",
                                      entry.id
                                    )
                                  }
                                  required={true}
                                />
                              </div>
                              <CTAButton
                                type="primary"
                                className="field"
                                small
                                onClick={(e) => handleDelete(e, entry.id)}
                                id="delete-user"
                              >
                                <Trash width={24} height={24} />
                              </CTAButton>
                              {/* <div className="element-round-form-btn">
                        <CTAButton type='primary' small onClick={(e) => handleDelete(e, entry.id)} id="delete-user">
                          <Trash width={24} height={24} /> 
                        </CTAButton>
                      </div> */}
                            </div>
                          </>
                        );
                      })}

                      {/* <div
                        className="sso-primary-content field-mapping-button"
                        onClick={handleAddNewField}
                      >
                        <div className="profile-field-column">
                          <Field
                            placeholder="Add New Field Mapping..."
                            noMargin
                            disabled={true}
                          />
                        </div>
                        <div className="profile-field-column">
                          <Field
                            placeholder="Add New Field Mapping..."
                            noMargin
                            disabled={true}
                          />
                        </div>
                      </div> */}
                      <div className="sso-new-field-content field-mapping-button" onClick={handleAddNewField}>
                        <div className="add-new-field-column">
                          <span className="custom-field-sso CTAGrey" onClick={handleAddNewField}>
                            Add New Field Mapping
                          </span>
                        </div>
                        <div className="add-new-field-column">
                          <span className="custom-field-sso CTAGrey" onClick={handleAddNewField}>
                            Add New Field Mapping
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="element-round-form-btn-submit">
                      <CTAButton id="task-page__cta" type="tertiary">
                        Submit
                      </CTAButton>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
