import React, { useState, useEffect } from 'react';
import Field from '../../Field/Field';
import CTAButton from '../../CTAButton/CTAButton';
import SweetAlert from 'react-bootstrap-sweetalert';
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import FlashMessage from "../../FlashMessage/Message";
import cookies from 'next-cookies';
import './AccessCode.scss';
import Close from '../../SVG/Close';
import { MAIN_CONFIG } from '../../../../config/main';
import { handleKeyDown } from '../../../Utility/ApplicationFormUtility';
import Alert from '../../SVG/Alert';
import { parseHtml } from '../../../Utility/HelperFunctions';

export default ({
    token,
    scholarshipId,
    setLoading,
    redirectURL = "",
    applicationId,
    submitClicked = false,
    setAccessCodeTrue = () => { },
    setModalState,
    editUrl = "",
    submitValidationPopup = false,
    readyToApply = false,
    redirectFunction = () => { },
    isRedirectSSO = false,
    redirectFuc = () => { },
    accessCodePopupDescription = "",
    accessCodeFailureMessage = "",
    accessCodeSuccessMessage = "",
    allowMultiple,
    handleClickApply = () => { }
}) => {

    const router = useRouter()
    const API = new KaleidoscopeAPI({});
    const [accessCode, setAccessCode] = useState("")
    let userContext = cookies('context').context
    const [updateAlert, setUpdateAlert] = useState(false)
    const [showAlert, setShowAlert] = useState(false)


    const onClose = (e) => {
        setUpdateAlert(false)
        setModalState({
            visible: false,
            className: '',
            content: []
        });
    }

    const handleRedirect = (e) => {
        onClose()

        if (readyToApply) {
            redirectFunction()
        } else if (isRedirectSSO) {
            redirectFuc()
        } else if (allowMultiple) {
            handleClickApply(e)
        } else if (redirectURL?.length > 0) {
            setLoading(true)
            setTimeout(() => {
                router.push(`${redirectURL}`, undefined, { shallow: true })
            }, 4000);
        }

        if (submitClicked) {
            setAccessCodeTrue({ isTrue: true, accessCode: accessCode })
        }

        if (submitValidationPopup) {
            setLoading(true)
            setTimeout(() => {
                window.location.href = editUrl
            }, 4000);
        }
    }

    const handleStaticRenewalSubmission = (e, response) => {
        e.preventDefault()
        const successMessage = accessCodeSuccessMessage || response.message
        const failureMessage = accessCodeFailureMessage || response.message

        if (response.success) {
            const showModal = () => (
                <>
                    <div className="save-changes-confirmation-alert modal-utility confirmation_modal">
                        <SweetAlert
                            confirmBtnBsStyle="primary"
                            customClass="user-confirmation-alert"
                            id="copied-clipboard"
                            onConfirm={() => handleRedirect(e)}
                            confirmBtnText="Okay"
                            custom
                            confirmBtnCssClass="cta-button CTAWhite tertiary"
                        >
                            <div className="success-alert-content">
                                <div>
                                    <img src={MAIN_CONFIG.STATIC_IMAGE_URL.Success_Icon} alt='success' height={'24px'} width={"24px"} />
                                </div>
                                <div className='content'>
                                    <p className='title'>Success!</p>
                                    <p className="CTAGrey">{parseHtml(successMessage)}</p>
                                </div>
                            </div>
                        </SweetAlert>
                    </div>
                </>
            );

            setUpdateAlert(showModal)
            setShowAlert(true)
        } else {
            const showModal = () => (
                <>
                    <div className="save-changes-confirmation-alert modal-utility confirmation_modal">
                        <SweetAlert
                            onConfirm={() => onClose()}
                            confirmBtnText="Close"
                            confirmBtnBsStyle="danger"
                            customButtons={
                                <div className='action-header-cross'>
                                    <CTAButton id='task-page__cta__cancel' type='inverse' onClick={() => onClose()}>Close</CTAButton>
                                </div>
                            }
                        >
                            <div className='success-alert-content'>
                                <div>
                                    <Alert />
                                </div>
                                <div className='content'>
                                    <p className='title'>Error!</p>
                                    <p className="CTAGrey">{parseHtml(failureMessage)}</p>
                                </div>
                            </div>
                        </SweetAlert>
                    </div>
                </>
            );
            setUpdateAlert(showModal)
            setShowAlert(true)
        }
    }

    const handleAccessCode = async (e) => {
        e.preventDefault()
        if (!submitClicked) {
            const response = await API.checkAccessCode({ 'token': cookies('context').token, scholarshipId: scholarshipId ? scholarshipId : router.query.scholarshipId, code: accessCode, applicationId: applicationId })

            if (response.success) {
                let arr = localStorage.getItem('hasAccessCode') ? JSON.parse(localStorage.getItem('hasAccessCode')) : []
                if (arr.includes(applicationId) === false) {
                    arr.push(applicationId)
                    localStorage.setItem('hasAccessCode', JSON.stringify(arr))
                    localStorage.setItem('accessCode', accessCode)
                }
                handleStaticRenewalSubmission(e, response)
            } else {
                handleStaticRenewalSubmission(e, response)
            }
        } else {
            setAccessCodeTrue({ isTrue: true, accessCode: accessCode })
        }
    }

    return (
        <>
            {showAlert && <div id='show-success-alret'>{updateAlert}</div>}
            <div id='task-modal-content' className='membership-enter-level-root'>
                <div className="membership-enter-level-modal">
                    <div className="access-code-head">
                        <div className="H1DesktopGreen membership-enter-level-modal-title">Access Code</div>
                        <button className='event-head H6DesktopGrey cancel-icon close-btn' onClick={onClose} onKeyDown={(e) => handleKeyDown(e, () => onClose(e))}><Close color={MAIN_CONFIG.COLORS.fillGrey} ariaLabel='Close' ariaLabelDescription='' /></button>
                    </div>
                    <div className="enter-level-modal-sub-title BodyDefaultRegularBlack">{accessCodePopupDescription}</div>
                </div>

                <div className="user-task-add__form">
                    <form onSubmit={(e) => handleAccessCode(e)}>
                        <div className="application-section__question" >
                            <label className="application-section__label">
                            </label>
                            <Field
                                id={'question_id'}
                                className="application-section__textarea"
                                placeholder='Please Enter Access Code.'
                                type={'text'}
                                value={accessCode}
                                required={true}
                                handleChange={(value) => setAccessCode(value)}
                            />
                        </div>

                        <div className="applicant-task__submit">
                            <CTAButton id='task-page__cta__cancel' type='inverse' outline onClick={onClose} buttonType="button">
                                Cancel
                            </CTAButton>

                            <CTAButton id='task-page__cta' type='tertiary'>
                                Submit
                            </CTAButton>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}